<template>
  <div class="content-table-mobile" >
      <div v-for="(item, index) in aTable" :key="index">
        <div class="content-all">
          <div class="content-left">
            <div
              class="content-head"
              v-for="(item, index) in headers"
              :key="index"
            >
              <p>{{ item.text }}</p>
            </div>
          </div>
          <div class="content-rigth"><p>{{ item.sPublicName }}</p></div>
        </div>
      <v-divider class="divider-global mt-3 mb-3"></v-divider>

      </div>

      <!-- <v-row>
        <v-col>
          <div class="footer-table">
            <div class="content-footer-pagination-dropdown">
              <div class="content-page-per-row">
                <p class="footer-text-pagination">Filas por página:</p>
              </div>
              <div class="content-dropdown-pagination">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="footer-table-dropdown"
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ iItemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="changeTable(5)">
                      <v-list-item-title class="footer-text-list"
                        >5</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="changeTable(10)">
                      <v-list-item-title class="footer-text-list"
                        >10</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="changeTable(30)">
                      <v-list-item-title class="footer-text-list"
                        >30</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="changeTable(50)">
                      <v-list-item-title class="footer-text-list"
                        >50</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="content-footer-pagination-arrows">
              <div class="content-current-page-num-page">
                <p class="footer-text-pagination">
                  {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
                </p>
              </div>
              <div class="content-pagination-arrow">
                <v-btn icon class="footer-table-buttons" @click="firstPage">
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn icon class="footer-table-buttons" @click="backPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn icon class="footer-table-buttons" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn icon class="footer-table-buttons" @click="lastPage">
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row> -->
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    aTable: Array,
  },
  data() {
    return {};
  },
};
</script>

<style >
.content-all {
  display: flex;
  padding: 15px;
  width: 100%;
  color: var(--primary-color-text) !important;
}
.content-left {
  width: 50%;
}
.content-rigth {
  width: 50%;
  text-align: end;
}
.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  border-radius: 10px;
}
.content-title {
  text-align: start;
}
.text-head {
  color: var(--primary-color-text) !important;
}
.content-text {
  /* text-align: end; */
}
.text-response {
  color: var(--primary-color-text) !important;
}
</style>